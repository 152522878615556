<template>
  <div>
    <div class="Topic">
      <el-card
        shadow="always"
        v-for="(item, index) in fixed_list"
        :key="index"
        class="Topic_item"
      >
        <div class="Topic_img">
          <img v-if="item.img_url" :src="item.img_url" />
        </div>
      </el-card>
    </div>
    <div class="Topic_box">
      <div class="Topic_find">
        <el-input
          v-model="topic_title"
          placeholder="请输入专题标题"
          @change="getTopicList"
          clearable
          @clear="getTopicList"
        >
          <el-button
            @change="getTopicList"
            slot="append"
            icon="el-icon-search"
          ></el-button>
        </el-input>
      </div>
      <div class="Topic_list">
        <div v-for="(item, index) in list" :key="index" class="Topic_list_item">
          <div class="Topic_list_itemImg">
            <img :src="item.cover" />
          </div>
          <div class="Topic_list_itemIcon">
            <el-dropdown
              trigger="click"
              size="mini"
              type="primary"
              placement="bottom"
              @command="handleCommand"
            >
              <i class="el-icon-caret-bottom Topic_list_itemIcon_i"></i>
              <!-- :disabled="!!fixed.img_url" -->
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(fixed, fixed_index) in fixed_list"
                  :key="fixed_index"
                  :command="{
                    weights: fixed_index.ord,
                    site: fixed_index,
                    item: item,
                  }"
                  class="Topic_list_itemIcon_num"
                >
                  {{ fixed_index + 1 }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-popconfirm
              title="这是一段内容确定删除吗？"
              @onConfirm="confirmInfo(item.id)"
            >
              <div class="Topic_del" slot="reference">
                <i class="el-icon-error"></i>
              </div>
            </el-popconfirm>
          </div>
        </div>
      </div>
      <div class="Topic_page">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="3"
          :current-page="currentPage"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 固定五个数组
      fixed_list: [
        {
          img_url: "",
          item_id: null,
          ord: 5,
        },
        {
          img_url: "",
          item_id: null,
          ord: 4,
        },
        {
          img_url: "",
          item_id: null,
          ord: 3,
        },
        {
          img_url: "",
          item_id: null,
          ord: 2,
        },
        {
          img_url: "",
          item_id: null,
          ord: 1,
        },
      ],
      // 展示数组
      list: [],
      // 专题标题
      topic_title: "",
      // 当前页数
      currentPage: 1,
      // 数据总条数
      count: 0,
    };
  },
  created() {
    this.getTopicList();
  },
  methods: {
    // 获取专题数据
    getTopicList() {
      this.$axios({
        url: this.$api + "/auth/ppt/album/list",
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
        params: {
          title: this.topic_title,
          page: this.currentPage,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          let topic_list = res.data.data.res;
          this.fixed_list.map((item) => {
            topic_list.map((list) => {
              if (item.ord == list.ord) {
                item.img_url = list.cover;
                item.item_id = list.id;
              }
            });
          });
          this.count = res.data.data.count;
          this.list = topic_list;
        }
      });
    },
    // 设置专题
    handleCommand(command) {
      this.fixed_list.map((item, index) => {
        if (item.item_id == command.item.id) {
          item.img_url = "";
          item.item_id = "";
        }
      });
      this.fixed_list[command.site].img_url = command.item.cover;
      this.fixed_list[command.site].item_id = command.item.id;
      this.$axios({
        url: this.$api + "/admin/ppt/handlerablum",
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
        data: {
          list: this.fixed_list,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "success",
          });
          this.getTopicList();
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
    // 确认状态
    confirmInfo(id) {
      this.$axios({
        url: this.$api + "/admin/ppt/handleppt",
        method: "DELETE",
        headers: {
          Authorization: localStorage.getItem("user_token"),
          "Content-Type": "application/json;charset=UTF-8;",
        },
        params: {
          cat: 2,
          item_id: id,
        },
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "success",
          });
          this.getTopicList();
        } else {
          this.$message({
            showClose: true,
            message: res.data.msg,
            type: "error",
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.Topic {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.Topic_item {
  margin: 10px;
  width: 20%;
}
.Topic_del {
  margin: 10px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  color: #949494;
  cursor: pointer;
}
.Topic_del > i:hover {
  color: #f56c6c;
}
.Topic_img {
  width: 100%;
  height: 180px;
}
.Topic_img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* 专题列表 */
.Topic_box {
  padding: 10px;
  margin: 10px;
  box-shadow: 0 0 10px #e3e3e3;
  background-color: #fff;
  overflow: auto;
}
.Topic_find {
  width: 20%;
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Topic_list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.Topic_list_item {
  width: 18%;
  margin: 1%;
  position: relative;
}
.Topic_list_itemImg {
  width: 100%;
  height: 180px;
  border-radius: 10px;
  overflow: hidden;
}
.Topic_list_itemImg > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.Topic_list_itemIcon {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  color: #fff;
  cursor: pointer;
  position: absolute;
  border-radius: 10px;
  align-items: flex-start;
  justify-content: space-between;
}
.Topic_list_itemIcon_i {
  font-size: 24px;
  margin: 10px;
  color: #000;
  border-radius: 100%;
}
.Topic_list_itemIcon_num {
  font-size: 14px;
}
.Topic_list_itemIcon_i:hover {
  color: #fff;
  background-color: #f56c6c;
}
i {
  outline: none;
}
.Topic_page {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 2049px) and (max-width: 4096px) {
  .Topic_img {
    height: 320px;
  }
  .Topic_list_itemImg {
    height: 320px;
  }
  .Topic_list_itemIcon_i {
    font-size: 36px;
  }
  .Topic_list_itemIcon_num {
    font-size: 18px;
  }
  .Topic_del {
    font-size: 28px;
  }
}
</style>